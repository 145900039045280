<template>
  <div>
    <div class="flix-form-group">
      <h3 class="flix-html-h3"><flixIcon :id="'cogwheel'" /> {{ $t('message.edit', {name: $tc('message.calendar', 1) + ' ' + $route.params.id}) }}</h3>
    </div>
    <transition name="flixFadeIn">
      <div :key="passwordSuccess">
        <div class="flix-form-group" v-if="!passwordSuccess">
          <input type="text" spellcheck="false" v-model="password" :placeholder="$t('message.password')" class="flix-form-control" />
        </div>
        <div class="flix-form-group" v-if="passwordSuccess">
          <div class="flix-form-group">
          <bookingList :data="data" :setReload="function(){ setReload() }" />
          </div>
          <div class="flix-form-group">
            <editBtn :onClick="function() { setDownloadPDF() }" :settings="{block: true}">
              <template v-slot:text>
                {{ $t('message.pdfExport') }}
              </template>
            </editBtn>
            <privatePDF v-if="downloadPDF" :id="data.ID"/>
          </div>
          <div class="flix-form-group" v-if="editBtn">
            <editBtn :onClick="function() { openEditor = !openEditor }" :settings="{block: true}">
              <template v-slot:text>
                {{ $t('message.edit', { name: $tc('message.calendar', 1) }) }}
              </template>
            </editBtn>
          </div>
          <transition name="flixFadeIn">
            <div class="flix-row"  v-if="openEditor" :key="openEditor">
              <hr class="flix-html-hr">
              <privateAssistent :settings="{data: data.data, onChange: function(){ setReload() }}"/>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    privatePDF () { return import('@/components/assistentPrivate/pdf') },
    privateAssistent () { return import('@/components/assistentPrivate/start') },
    bookingList () { return import('@/components/privateEvent/bookingList') }
  },
  props: {
    data: Object,
    setReload: Function
  },
  data () {
    return {
      downloadPDF: false,
      editBtn: true,
      openEditor: false,
      open: false,
      verifyPassword: this.$createPrivatePassword(this.data.ID),
      password: '',
      passwordSuccess: false,
      timeout: false
    }
  },
  watch: {
    password (val) { if (val === this.verifyPassword) { this.passwordSuccess = true } }
  },
  methods: {
    setDownloadPDF () {
      this.downloadPDF = true
      setTimeout(function () {
        this.downloadPDF = false
      }.bind(this), 1000)
    },
    setAutoOpenEditor () {
      if (typeof this.data.data.privateBookings !== 'object' || this.data.data.privateBookings.length === 0) {
        this.openEditor = true
        this.editBtn = false
        return false
      }
    }
  },
  mounted () {
    this.setAutoOpenEditor()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
